.visuallyhidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}

.copybutton {
  background-color: #fff;
  border: 0;
  outline: 0;
  cursor: pointer;
  opacity: 1;
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 9;
  border-radius: 24px;
}

.button-tooltip-container {
  display: flex;
  align-items: center;
  margin-top: 16px;
  min-height: 30px;
}

#custom-tooltip {
  margin-left: 40px;
  padding: 5px 12px;
  background-color: #000000df;
  border-radius: 4px;
  color: #fff;
  z-index: 99999;
  position: absolute;
  top: 75px;
  left: 34px;
}
