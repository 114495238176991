*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* .navbar input[type="checkbox"],
.navbar .hamburger-lines{
    display: none;
} */

.navbar {
  /* box-shadow: 0px 5px 10px 0px #e9e2e2; */
  /* position: fixed; */
  width: 100%;
  background-color: #fff;
  /* background: linear-gradient(90deg, rgba(255,234,239,1) 0%, rgba(255,234,239,1) 35%, rgba(250,250,250,1) 100%); */
  color: #000000;
  /* opacity: 0.85; */
  z-index: 100;
}

.navbar-container {
  padding: 12px 0;
}

.menu-items {
  order: 2;
  display: flex;
}
.logo {
  order: 1;
}
.auth-button {
  order: 3;
  cursor: pointer;
  font-size: 14px;
}

.menu-items li {
  list-style: none;
  margin-left: 1.5rem;
  font-size: 16px;
}

.navbar a {
  color: #444;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.navbar a:hover {
  color: #117964;
}

@media (max-width: 1020px) {
  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines {
    display: block;
  }

  .navbar-container {
    display: block;
    position: absolute;
    height: 64px;
  }

  .navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .navbar-container .hamburger-lines {
    display: block;
    height: 20px;
    width: 25px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navbar-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #333;
  }

  .navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  .menuitems {
    top: 0;
    position: fixed;
    padding-top: 100px;
    background: #fff;
    height: 100vh;
    min-width: 230px;
    /* transform: translate(-150%); */
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    padding-left: 40px;
    transition: transform 0.5s ease-in-out;
    box-shadow: 5px 0px 10px 0px #aaa;
  }

  .navbar .menu-items li {
    margin-bottom: 1.8rem;
    font-weight: 500;
    font-size: 14px;
  }

  .logo {
    position: absolute;
    top: 14px;
    left: 35px;
  }
  .auth-button {
    position: absolute;
    top: 10px;
    right: 0px;
  }
  .auth-icon {
    top: 8px;
  }

  .navbar-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
  .navbar-container input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
}

@media (max-width: 620px) {
  .navbar-container input[type="checkbox"]:checked ~ .logo,
  .navbar-container input[type="checkbox"]:checked ~ .auth-button .sign {
    display: none;
  }
  .navbar-container input[type="checkbox"]:checked ~ .auth-button .join,
  .navbar-container input[type="checkbox"]:checked ~ .auth-button .profile {
    display: block;
  }
}

@media (max-width: 390px) {
  .navbar-container {
    height: 50px;
  }
  .navbar-container .auth-button .join {
    display: none;
  }
  .navbar-container .auth-button {
    top: 16px;
  }
  .navbar-container .auth-icon {
    top: 8px;
  }

  .navbar-container .auth-button img .profile {
    padding: 0;
  }
  .navbar-container .auth-button .signin-arrow {
    padding-left: 5px;
  }
}
